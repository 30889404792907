.tabs {
    display: flex;
}

.tab {
    padding: 10px;
    cursor: pointer;
}

.sortable-list {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 13px 46px rgba(0, 0, 0, 0.1);
}

.sortable-list .item {
    margin-top: 3px;
    border: 1px solid #ccc;
    justify-content: space-between;
    display: flex;
    cursor: move;
    background: #ffffff;
    align-items: center;
    border-radius: 10px;
    padding: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.item.dragging {
    opacity: 0.6;
}

.details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}