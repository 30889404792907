/* Grid.css */
.grid-container {
    display: grid;
    grid-template-columns: repeat(50, 20px);
    grid-template-rows: repeat(4, 20px);
    gap: 5px;
    background-color: white;
    padding: 10px;
}

.grid-row {
    display: flex;
    justify-content: space-between;
}

.grid-item {
    background-color: white;
    border: 1px solid rgb(234, 232, 232);
    width: 20px;
    height: 20px;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* Hace que el div tenga forma de círculo */
    background-color: red;
    /* Establece el color rojo del círculo */
}

.circle-purple {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* Hace que el div tenga forma de círculo */
    background-color: rgb(50, 42, 93);
    /* Establece el color del círculo */
}

.circle-green {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* Hace que el div tenga forma de círculo */
    background-color: rgb(8, 199, 37);
    /* Establece el color del círculo */
}